import {Component, OnDestroy, SecurityContext} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {marked} from 'marked';

import {CardComponent} from '../../components/card/card.component';
import {CustomInputComponent} from '../../components/custom-input/custom-input.component';
import {
  GENERAL_ERROR_CONSTANT,
  LABEL_CONSTANT,
  LOGIN_CONSTANT,
  PAGE_TITLE_CONSTANT,
  PLACEHOLDER_CONSTANT,
  ROUTE_CONSTANT,
} from '../../constant/constant';
import {
  DomainOrganizationService, IOrganization,
} from '../../core/services/domain-organization.service';
import {ButtonComponent} from '../../components/button/button.component';
import {SystemAccessNotificationService} from '../../core/services/system-access-notification.service';
import {CognitoService} from '../../core/auth/cognito.service';
import {HttpErrorService} from '../../core/services/http-error.service';
import {CognitoOrgIdService} from '../../core/auth/cognito-org-id.service';
import {ValidationService} from '../../shared/services/validation.service';
import {AppAuthorizationService} from '../../core/auth/app-authorization.service';

export interface IOrganizationLoginForm {
  userName: FormControl<string | null | undefined>;
  password: FormControl<string | null>;
  newPassword: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}
@Component({
  selector: 'app-organization-login',
  standalone: true,
  templateUrl: './organization-login.component.html',
  styleUrl: './organization-login.component.scss',
  imports: [ReactiveFormsModule, CardComponent, CustomInputComponent, ButtonComponent],
})
export class OrganizationLoginComponent implements OnDestroy {
  LABEL_CONSTANT = LABEL_CONSTANT;
  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;
  organizationLoginForm: FormGroup<IOrganizationLoginForm>;
  markdown?: string;
  isNewPasswordFlow: boolean = false;
  private readonly orgId: string;
  private clientId: string = '';

  constructor(
    private router: Router,
    private activatedRoutes: ActivatedRoute,
    private _domainOrganizationService: DomainOrganizationService,
    private _systemAccessNotificationService: SystemAccessNotificationService,
    private sanitizer: DomSanitizer,
    private _cognitoService: CognitoService,
    private _cognitoOrgIdService: CognitoOrgIdService,
    private _httpErrorService: HttpErrorService,
    private title: Title,
    private _appAuthorizationService: AppAuthorizationService,
  ) {
    this.organizationLoginForm = new FormGroup<IOrganizationLoginForm>({
      userName: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.required, ValidationService.emailValidator()],
      ),
      password: new FormControl(null, Validators.required),
      newPassword: new FormControl(null),
      confirmPassword: new FormControl(null),
    });
    this.orgId = this.activatedRoutes.snapshot.params['orgId'];
    this._cognitoOrgIdService.orgId.set(this.orgId);
  }

  ngOnInit(): void {
    this.fetchUserNameFromURL();

    this._systemAccessNotificationService
      .getSystemAccessNotification()
      .subscribe((notes: string) => {
        this.markdown = this.sanitizer.sanitize(SecurityContext.HTML, marked(notes)) as string;
      });
  }

  private fetchUserNameFromURL(): void {
    this.activatedRoutes.queryParams.subscribe((params) => {
      const userName = params?.['login_hint'];
      this.clientId = params?.['client_id'];
      if (userName) {
        this.organizationLoginForm.setValue({
          userName: userName,
          password: null,
          newPassword: null,
          confirmPassword: null,
        });
      } else {
        this.organizationLoginForm.controls.userName.enable();
      }
      const orgDetails = this._cognitoService.getOrgDetails();
      if (orgDetails.defaultAppClientId && orgDetails.userPoolId) {
        this.clientId = this.clientId || orgDetails.defaultAppClientId;
        this.routeToApplicationIfAuthenticated(orgDetails);
      } else {
        void this.getDefaultOrgDetails();
      }
    });
  }

  private async getDefaultOrgDetails(): Promise<void> {
    try {
      const orgDetails = await lastValueFrom(
        this._domainOrganizationService.getOrgDetails(this.orgId),
      );
      this.title.setTitle(`${PAGE_TITLE_CONSTANT.LOGIN} | ${orgDetails.name}`);
      this.clientId = this.clientId || orgDetails?.defaultAppClientId;
      if (!orgDetails?.userPoolId || !orgDetails?.defaultAppClientId) {
        void this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
      } else {
        // default appClientId used by the cognito user pool to store the auth information
        // if we use respective client ID then we face issue while logging into other application
        this._cognitoService.setOrgDetails(orgDetails, this.clientId);
        this.routeToApplicationIfAuthenticated(orgDetails);
      }
    } catch (error: any) {
      if (error.status === 404) {
        void this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
      }
    }
  }

  public onSubmit(): void {
    this._httpErrorService.httpError.set('');
    if (!this.isNewPasswordFlow) {
      void this.signIn();
    } else {
      this.onNewPassword();
    }
  }

  public async signIn() {
    const {['userName']: email, password} = this.organizationLoginForm.getRawValue();
    try {
      const data = await this._cognitoService
        .signIn(
          {
            email: email as string,
            password: password as string,
          });
      if (data?.nextStep?.signInStep === LOGIN_CONSTANT.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
        this.isNewPasswordFlow = true;
      } else {
        void this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      }
    } catch (error) {
      if (error instanceof Error) {
        this._httpErrorService.httpError.set(error.message);
      } else {
        console.error(error)
        this._httpErrorService.httpError.set(GENERAL_ERROR_CONSTANT.UNEXPECTED_ERROR);
      }
    }
  }

  public onNewPassword(): void {
    this._cognitoService
      .handleUpdatePassword(this.organizationLoginForm.get('confirmPassword')?.value as string)
      .then(() => {
        void this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      });
  }

  private routeToApplicationIfAuthenticated(orgDetails: IOrganization): void {
    this._cognitoService.isAuthenticated().then((auth: boolean) => {
      if (auth) {
        void this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      }
    });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
