import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SignInOutput} from 'aws-amplify/auth';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';

import {CustomInputComponent} from '../../../components/custom-input/custom-input.component';
import {HTTP_ERROR_CONSTANT, LABEL_CONSTANT, LOGIN_CONSTANT, PLACEHOLDER_CONSTANT} from '../../../constant/constant';
import {ValidationService} from '../../../shared/services/validation.service';
import {ButtonComponent} from '../../../components/button/button.component';
import {CognitoService} from '../../../core/auth/cognito.service';
import {CognitoOrgIdService} from '../../../core/auth/cognito-org-id.service';
import {SIGN_IN_STATE} from '../organization-login.component';
import {AppAuthorizationService} from '../../../core/auth/app-authorization.service';
import {HttpErrorService} from '../../../core/services/http-error.service';

export interface ISignInForm {
  userName: FormControl<string | null | undefined>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [ReactiveFormsModule, CustomInputComponent, ButtonComponent],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
})
export class SignInComponent implements OnInit, OnDestroy {
  @Input() public signInState: string = '';
  @Input() public clientId?: string;
  @Input() public userName: string = '';
  @Output() setSignInState: EventEmitter<string> = new EventEmitter<string>();
  @Output() signInOuput: EventEmitter<any> = new EventEmitter<any>();

  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;
  LABEL_CONSTANT = LABEL_CONSTANT;
  signInForm: FormGroup<ISignInForm>;

  constructor(
    private _cognitoService: CognitoService,
    private _cognitoOrgIdService: CognitoOrgIdService,
    private _appAuthorizationService: AppAuthorizationService,
    private _httpErrorService: HttpErrorService,
    private _validationService: ValidationService,
  ) {
    this.signInForm = new FormGroup<ISignInForm>({
      userName: new FormControl(null, [Validators.required, ValidationService.emailValidator()]),
      password: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.userName) {
      this.signInForm.controls.userName.setValue(this.userName);
      this.signInForm.controls.userName.updateValueAndValidity();
    }
  }

  public switchToForgotPasswordFlow(): void {
    this.setSignInState.emit(SIGN_IN_STATE.FORGOT_PASSWORD);
  }

  public onSubmit(): void {
    if (this.signInForm.valid) {
      this.signIn();
    } else {
      // shows validation error if the username is not present
      this._validationService.validateAllFormFields(this.signInForm);
    }
  }

  private signIn(): void {
    const {['userName']: email, password} = this.signInForm.getRawValue();
    this._cognitoService
      .signIn({
        email: email as string,
        password: password as string,
      })
      .then((data: SignInOutput) => {
        if (
          data?.nextStep?.signInStep === LOGIN_CONSTANT.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED
        ) {
          this.setSignInState.emit(SIGN_IN_STATE.CONFIRM_SIGN_IN);
        } else if (data?.nextStep?.signInStep === LOGIN_CONSTANT.CONTINUE_SIGN_IN_WITH_TOTP_SETUP) {
          this.setSignInState.emit(SIGN_IN_STATE.MFA_SETUP);
          this.signInOuput.emit(data.nextStep);
        } else if (data?.nextStep?.signInStep === LOGIN_CONSTANT.CONFIRM_SIGN_IN_WITH_TOTP_CODE) {
          this.setSignInState.emit(SIGN_IN_STATE.MFA_PROMPT);
          this.signInOuput.emit(data.nextStep);
        } else {
          this._appAuthorizationService.routeToClientApp(
            this._cognitoOrgIdService.orgId() as string,
            this.clientId as string,
          );
        }
      })
      .catch((error: Error) => {
        switch (error.name) {
          case 'UserNotFoundException':
          case 'PasswordIncorrectException':
          case 'UserNotConfirmedException':
          case 'NotAuthorizedException':
            this._httpErrorService.httpError.set(HTTP_ERROR_CONSTANT.INCORRECT_USER_NAME_OR_PASSWORD);
            break;
          default:
            this._httpErrorService.httpError.set(error.message);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
