<form class="form-container" [formGroup]="mfaPromptForm" (ngSubmit)="onVerify()">
      <div class="mfa-prompt">
        <h3>{{ LABEL_CONSTANT.ENTER_CODE }}</h3>
        <app-custom-input
          [formControlName]="'verificationCode'"
          [type]="'text'"
          [id]="'verification-code'"
          [label]="MFA_SETUP_CONSTANT.ENTER_CODE"
          [icon]="'lock.svg'"
          [placeholder]="PLACEHOLDER_CONSTANT.SIX_DIGIT_CODE"
          [required]="true"/>
      </div>
  <app-button
    [type]="'submit'"
    [className]="'primary-form-button'"
    [label]="isVerifying ? LABEL_CONSTANT.VERIFYING : LABEL_CONSTANT.ENTER"/>
</form>
