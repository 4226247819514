<app-card [systemAccessNotification]="markdown">
  <form class="form-container" [formGroup]="organizationLoginForm" (ngSubmit)="onSubmit()">
    <div>
      @if (!isNewPasswordFlow) {
        <app-custom-input
          [formControlName]="'userName'"
          [type]="'text'"
          [id]="'username'"
          [label]="LABEL_CONSTANT.USERNAME_EMAIL"
          [icon]="'user-input-icon.svg'"
          [placeholder]="PLACEHOLDER_CONSTANT.EMAIL_FORMAT"
          [required]="true"
        />
        <app-custom-input
          [formControlName]="'password'"
          [type]="'password'"
          [id]="'password'"
          [label]="LABEL_CONSTANT.PASSWORD"
          [icon]="'lock.svg'"
          [showHide]="true"
          [required]="true"
        />
      } @else {
        <app-custom-input
          [formControlName]="'newPassword'"
          [type]="'password'"
          [id]="'new-password'"
          [label]="LABEL_CONSTANT.NEW_PASSWORD"
          [icon]="'lock.svg'"
          [showHide]="true"
          [required]="true"
        />
        <app-custom-input
          [formControlName]="'confirmPassword'"
          [type]="'password'"
          [id]="'confirm-password'"
          [label]="LABEL_CONSTANT.CONFIRM_PASSWORD "
          [icon]="'lock.svg'"
          [showHide]="true"
          [required]="true"
        />
      }
    </div>
    <app-button
      [type]="'submit'"
      [disable]="!organizationLoginForm.valid"
      [className]="'primary-form-button'"
      [label]="LABEL_CONSTANT.LOGIN"
    />
  </form>
</app-card>
