import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';

import {LABEL_CONSTANT, PLACEHOLDER_CONSTANT} from '../../../constant/constant';
import {CustomInputComponent} from '../../../components/custom-input/custom-input.component';
import {ButtonComponent} from '../../../components/button/button.component';
import {HttpErrorService} from '../../../core/services/http-error.service';
import {CognitoService} from '../../../core/auth/cognito.service';
import {SIGN_IN_STATE} from '../organization-login.component';
import {ValidationService} from '../../../shared/services/validation.service';

export interface IForgotPasswordForm {
  userName: FormControl<string | null>;
}

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, CustomInputComponent, ButtonComponent],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnDestroy {
  @Input() public userName: string | undefined;
  @Output() setSignInState: EventEmitter<string> = new EventEmitter<string>();
  @Output() setResetFlowUsername: EventEmitter<string> = new EventEmitter<string>();

  LABEL_CONSTANT = LABEL_CONSTANT;
  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;
  forgotPasswordForm: FormGroup<IForgotPasswordForm>;

  constructor(
    private _cognitoService: CognitoService,
    private _httpErrorService: HttpErrorService,
    private _validationService: ValidationService,
  ) {
    this.forgotPasswordForm = new FormGroup<IForgotPasswordForm>({
      userName: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.userName) {
      this.forgotPasswordForm.controls.userName.setValue(this.userName);
    }
  }

  public onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      this.forgotPassword();
    } else {
      // shows validation error if the username is not present
      this._validationService.validateAllFormFields(this.forgotPasswordForm);
    }
  }

  public switchToLoginFlow(): void {
    this.setSignInState.emit(SIGN_IN_STATE.SIGN_IN);
  }

  private forgotPassword(): void {
    const username = this.forgotPasswordForm.getRawValue().userName as string;
    this._cognitoService
      .resetPassword(username)
      .finally(()=> {
        this.setResetFlowUsername.emit(username);
        this.setSignInState.emit(SIGN_IN_STATE.RESET_PASSWORD);
      });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
